.listContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
}

  .listItem {
    display: flex;
    width: 95%;
    height: auto;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 5px;
    margin: 10px;
  }

    .itemHeader {
      display: flex;
      width: 100%;
      flex-direction: column;
      border-bottom: 1px dashed var(--redColor);
      padding: 3px;
      margin-bottom: 3px;
    }

    .itemTitles {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 3px 0px 3px 0px
    }

    .orderTitlesHeader {
      display: flex;
      flex-direction: row;
      margin-bottom: 3px;
      border-bottom: 0.5px dashed var(--mainColor);
      justify-content: space-between;
      align-items: center;
    }

      .orderItem {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        min-width: 100%;
      }

      .itemName {
        display: flex;
      }

      .itemPrice {
        display: flex;
      }

    .itemFooter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-top: 0.5px dashed var(--mainColor);
    }
