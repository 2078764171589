
.headerWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 5px;
}

  .headerRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    min-width: 100%;
    justify-content: flex-start;
  }

  .headerColumn {
    display: flex;
    flex-direction: column;
  }

  .headerRow.flexEnd {
    justify-content: flex-end;
  }
