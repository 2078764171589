h1 {
  font-size: 1.5rem;
  font-weight: 700;
}

h1.white {
  font-size: 1.5rem;
  font-weight: 700;
  color: white
}

h1.red {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--redColor)
}

h2 {
  font-size: 1rem;
  font-weight: 700;
  color: var(--mainColor)
}

h2.white {
  color: white
}

h2.red {
  color: var(--redColor)
}

h2.green {
  color: var(--greenColor)
}

h3.light {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--notFound);
  text-align: center;
}

h4 {
  font-size: 0.8rem;
  font-weight: 300;
}

h4.white {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
}

h4.white-total {
  font-size: 0.8rem;
  font-weight: 300;
  color: white;
  margin-right: 5px;
}

h4.strong-red {
  font-size: 0.8rem;
  font-weight: 700;
  color: var(--redColor)
}

h4.strong-white {
  font-size: 0.8rem;
  font-weight: 700;
  color: white
}

h4.strong-gray {
  font-size: 0.9rem;
  font-weight: 700;
  color: gray
}

h4.strong-black {
  font-size: 0.9rem;
  font-weight: 700;
  color: var(--mainColor)
}

h5 {
  font-size: 0.7rem;
}
