.userMenuWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 50;
  top: 0px;
  left: 0px;
  opacity: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: var(--redColor);
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1
}

  @keyframes fadeIn {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
  .menuCloseImg {
    width: 44px;
    height: 44px;
    border-radius: 6px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .userMenuItemWrapper {
    display: flex;
    height: 44px;
    width: 80%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dashed white
  }
