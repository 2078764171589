.modalWrapper {
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 12
}

  .modalWrapper.bg {
    background-color: black;
    opacity: 0.3;
  }

  .modalContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 90vw;
    height: 90vh;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    padding: 10px;
    z-index: 15
  }

  .modalHeader {
    display: flex;
    flex-direction: row;
    height: 60px;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--redColor);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 10px
  }

  .modalTitle {
    display: flex;
    padding-bottom: 5px;
    margin-bottom: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .orderContainer {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0px;
    left: 50%;
    width: 100%;
    padding: 10px;
    height: 140px;
    transform: translate(-50%);
    border-top: 2px dashed var(--redColor)
  }

  .qtyRow {
    display: flex;
    width: 100%;
    height: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .qtySettingContainer {
    display: flex;
    width: 120px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 30px;
  }

    .qtyNumberContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 30px;
    }

    .qtyButton {
      width: 30px;
      height: 30px;
    }

    .closeButton {
      width: 30px;
      height: 30px;
      background-color: white;
      border-radius: 15px;
    }

    .addToCartButton {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 46px;
      width: 100%;
      border-radius: 6px;
      background-color: var(--greenColor)
    }
