@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;700;900&display=swap');

:root {
  --mainColor: #002C3E;
  --redColor: #B70015;
  --notFound: #A5B6BD;
  --greenColor: #4BAE4F;
}

html{
  scroll-behavior: smooth;
}

* {
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: scroll;
    color: var(--mainColor);
    margin: 0px;
    font-size: 1rem;
    font-weight: 400;
    overflow-x: hidden;
    text-decoration: none;
    outline: none;
    border: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

/* GLOBAS STYLES */
.bigContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: space-evenly;
}

.bigContainerImage {
  display: flex;
  width: 90%;
  height: 300px;
  object-fit: contain;
}
/* END GLOBAL STYLES

/* IMAGES */

.smallIcon {
  width: 40px;
  height: 40px;
  transform: rotate();
}

.smallIcon.border {
  border-radius: 6px;
}

.searchIcon {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}

.headerLogo {
  display: flex;
  height: 100px;
  object-fit: contain;
}

.logoImage {
  display: flex;
  position: absolute;
  left: 50%;
  top: 25px;
  height: 50px;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.bigImage {
  width: 95%;
  height: 300px;
  object-fit: contain;
}

/* IMAGES END */

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding: 5px;
  background-color: white
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoContainer.center {
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center
}

.row.dashed {
  position: absolute;
  top: 10px;
  width: 99%;
  padding: 5px;
  padding-bottom: 3px;
  margin-bottom: 3px;
  border-bottom: 1px dashed white
}

.row.dashedUp {
  position: absolute;
  bottom: 80px;
  padding-bottom: 3px;
  margin-bottom: 3px;
  padding-top: 3px;
  margin-top: 3px;
  border-top: 1px dashed white;
  justify-content: space-between;
  width: 99%
}

.column {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* MENU TITLES */

.menuTitlesRow {
  display: flex;
  flex-direction: row;
  width: auto;
  padding: 8px;
  height: 30px;
  margin-bottom: 3px;
  overflow: hidden;
  align-items: center;
  overflow-x: scroll;
  border-bottom: 1.5px dashed var(--redColor)
}

  .menuTitleItem {
    display: flex;
    width: auto;
    height: 30px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 6px;
    border: 0px solid var(--redColor);
    padding: 3px;
    margin-right: 3px;
    overflow: visible;
    white-space: nowrap;
  }

  .menuTitleItem:focus {
    background: white
  }

/* MENU TITLES END */

/* SEARCH */

  .searchWrapper {
    display: flex;
    width: 100%;
    min-height: 50px;
    margin-top: 8px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .input {
    display: flex;
    width: calc(100% - 45px);
    height: 40px;
    border-radius: 6px;
    background-color: #edebeb;
    font-size: 1rem;
    font-weight: 700;
    color: var(--redColor)
  }

  .input.small {
    width: 60px;
    text-align: center;
  }

  .notFoundWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
    justify-content: center;
  }

/* SEARCH END */

/* MENU ITEM */

.menuWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vw;
  height: auto;
  overflow: scroll;
  padding-bottom: 110px;
}

.menuItemWrapper {
  display: flex;
  flex-direction: column;
  min-height: 80px;
  width: 100%;
  margin-bottom: 3px;
}

  .menuItemHeader {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 100%;
    justify-content: space-between;
  }

  .menuItemHeaderName {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    height: auto;
  }

  .headerTitleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .headerTitleBulb {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    min-height: 20px;
    border-radius: 10px;
    background-color: var(--redColor);
    margin-right: 3px;
  }

/* MENU ITEM END */

/* CART */

  .cartWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    width: 100vw;
    height: 300px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: var(--redColor);
    position: fixed;
    bottom: 0px;
    right: 0px;
    animation-name: rollDown;
    animation-duration: 0.6s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    overflow: visible;
  }

  @keyframes rollUp {
    0% {
      height: 30px;
    }
    100% {
      height: 300px;
    }
  }

  @keyframes rollDown {
    0% {
      height: 300px;
    }
    100% {
      height: 30px;
    }
  }

  .cartInfoBulb {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -50px;
    width: 55px;
    height: 55px;
    background-color: var(--redColor);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;

  }

  .cartListWrapper {
    display: none;
    width: 100%;
    padding-left: 3px;
    padding-right: 3px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cartItemsContainer {
    display: flex;
    width: 99%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 145px;
    overflow: scroll;
  }

  .cartItem {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 20px;
    height: auto;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    scrollbar-width: thin;
    scrollbar-color: green
  }

  .fullBtn {
    display: flex;
    position: fixed;
    bottom: 10px;
    align-items: center;
    justify-content: center;
    height: 46px;
    width: calc(100% - 20px);
    border-radius: 6px;
    background-color: var(--greenColor)
  }

  .fullBtn.up {
    bottom: 66px;
  }

  .fullBtn.red {
    background-color: var(--redColor)
  }

/* CART END */

.registrationFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  min-height: 550px;
  overflow: scroll;
}

.columnHeaderContainer {
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-bottom: 1px dashed var(--redColor);
  text-align: flex-start;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.optionSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100px;
  border-radius: 6px;
  border: 2px dotted var(--redColor);
  background-color: white;
  margin-right: 5px;
}

.optionSelection.selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: 100px;
  border-radius: 6px;
  background-color: var(--redColor);
}
