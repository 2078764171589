.loginWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  align-items: center;
  margin-top: 50px;
  padding: 10px;
  overflow: hidden;
}

.loginInfo {
  display: flex;
  text-align: center;
  height: auto;
  margin-top: 70px;
  margin-bottom: 10px;
}

.loginHeader {
  display: flex;
  flex-direction: column;
}

.phoneInputWrapper {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  margin-top: 20px;
}

.phoneInputWrapper.code {
  flex-direction: row;
}

.phoneInput {
  display: flex;
  width: calc(100% - 45px);
  height: 40px;
  border-radius: 6px;
  background-color: #edebeb;
  font-size: 1rem;
  font-weight: 700;
  color: var(--redColor)
}

.phoneInput.code {
  width: 40px;
  margin-right: 5px;
  text-align: center;
}

.loginDataContainer {
  display: flex;
  width: 100vw;
  height: calc(100vh - 286px);
  background-color: var(--redColor);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: hidden;
}

.loginImageWrapper {
  display: flex;
  max-height: 300px;
  width: 90%;
  object-fit: contain;
}

.captcha {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 10px;
}

.loginImage {
  display: flex;
  height: 100%;
  object-fit: contain;
}

.loginButton {
  display: flex;
  position: fixed;
  bottom: 20px;
  align-items: center;
  width: 95%;
  align-self: center;
  transform: translate(-50%; -50%);
  justify-content: center;
  height: 46px;
  background-color: var(--redColor);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  cursor: pointer;

}

@media only screen and (min-width: 667px) {
  .loginWrapper {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  .loginWrapper.column {
    flex-direction: column;
    align-items: flex-start;
  }

  .loginImageWrapper {
    display: flex;
    margin-top: 30px;
    flex-direction: row;
    justify-content: flex-end;
    height: calc(100vh - 50px - 70px - 80px);
  }

  .loginInfo {
    margin-top: 50px;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 50px - 70px - 80px)
  }
}
